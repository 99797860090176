
import Vue from "vue";
import User from "~/entities/User";
import {mapActions, mapGetters, mapMutations} from "vuex";
import Session from "~/entities/Session";
import Modal from "./Modal.vue";
import Notice from "./Notice.vue";
var timer = null as any;


const pages = {
  H: {route:'/', step:null},
  S: {route:'/ship', step:null},
  1: {route:'/ship', step:1},
  2: {route:'/ship', step:2},
  3: {route:'/ship', step:3},
  4: {route:'/ship', step:4},
  5: {route:'/ship', step:5}
}

export default Vue.extend({
  //this is here mostly so that we can use it to insert logic on a global level without cluttering the other sections and components
  name: "TopContent",
  components: { Modal, Notice },
  data() {
    return {
      //validation : null
      sessionValid : false,
      sessionCreation : 0,
      sessionDuration : (1000 * 60) * 20, //1 sec times 60 times 20 = 20 minutes
      tokenDuration : 0,
      defaultSessionDuration : (1000 * 60) * 20, //1 sec times 60 times 20 = 20 minutes
      sessionNearEnd : (1000 * 60), //1 sec times 60 = 1 minute
      stepDuration : 5000,
      timeLeft: 0,
      countDown: false as boolean | NodeJS.Timeout,
      isOpen: false,
      isOpenLogout: false
    }
  },
  computed:{
    // Vuex getters
    ...mapGetters({
      session: 'user/session',
      globalNotices: 'errorsBag/globalNotices' as any,
      alertResponses: 'errorsBag/alertResponses' as any,
      stepNumber: 'ship/stepNumber',
    }),
    showAlert() {
      if (this.alertResponses) {
        let path = this.$route.path;
        let accept:any[] = []
        this.globalNotices.forEach((notice:string, index:number) => {
          let valArray = ['H', 'S', 1, 2, 3, 4, 5];
          let splitType = notice.split("");
          let exist:boolean = valArray.some(function(v:string | number) { return notice.indexOf(v.toString()) >= 0; });
          if(exist) {
            splitType.forEach(index => {
              if(pages[index]?.route === path && pages[index]?.step === null || path === '/ship' && this.stepNumber === pages[index]?.step) {
                accept.push({id:this.globalNotices[index], show:true});
              }
            })
          } else if(accept.length === 0) {
            accept.push({id:this.globalNotices[index], show:false});
          }
        });
        return accept;
      } else {
        return { id:null, show:false}
      }
    }
  },
  async created() {
    try{
      const _this = (this as any);
      await (this as any).startSession();//init the session object in the index store. IF there is a previous session, check if it has searches and take them. If not, init empty array.
      setTimeout(() => {
        _this.judgeSession();
        if(timer === null){
          timer = setInterval(_this.runInterval, _this.stepDuration);
        }else{
          _this.resetTimer();
        }
      }, 1000);
      if((this as any).session && (this as any).session.sessionType){
        await (this as any).extendSession();
      }
      this.requestNotifications(new Date());
    }catch (e){
      console.log(e);
    }
  },
  methods: {
    ...mapMutations({
      setSessionTime: 'user/setSessionTime',
      setTimeoutLogout: 'user/setTimeoutLogout'
    }),
    // Vuex actions
    ...mapActions({
      startSession: 'user/startSession',
      extendSession: 'user/extendSession',
      requestNotifications: 'errorsBag/requestNotifications',
      logoutUser: 'user/logoutUser',
      getAppVersion: 'getAppVersion'
    }),
    resetTimer() {
      clearInterval(timer);
      timer = null;
      timer = setInterval((this as any).runInterval, (this as any).stepDuration);
    },
    async isSessionValid(){
      if((this as any).session && (this as any).session.constructor === Session && (this as any).session.sessionType){
        
        let now = new Date().getTime();
      //The comparison to know if a session expired or not will be done with 
      //the expiration date and time extracted from the JWT with the current 
      //date and time both in UTC
      let verifyUser = (this as any).session.user === undefined || (this as any).session.user.exp === undefined;
      let expirationDate = verifyUser? now + (this as any).defaultSessionDuration: (this as any).session.user.exp * 1000;
      let sessionEndTime =  new Date(expirationDate);
      let nowTime = new Date(now);
      if(nowTime.toISOString() < sessionEndTime.toISOString()){
        return true;
       }
      } else {
        //check if session is there but is not public
        if((this as any).session && (this as any).session.constructor === Session){
          return true;
        }
      }
      return false;
    },
    async isSessionAboutToEnd(){
      let now = new Date().getTime();
      //The comparison to know if a session expired or not will be done with 
      //the expiration date and time extracted from the JWT with the current 
      //date and time both in UTC
      let verifyUser = (this as any).session.user === undefined || (this as any).session.user.exp === undefined;
      let expirationDate = verifyUser? now + (this as any).defaultSessionDuration: (this as any).session.user.exp * 1000;
      let sessionEndTime =  new Date(expirationDate - (this as any).sessionNearEnd);
      let nowTime = new Date(now);
      if(nowTime.toISOString() < sessionEndTime.toISOString()){
        return true;
       }
      return false;         
    },
    async judgeSession() {
      const valid = await (this as any).isSessionValid();
      const about = await (this as any).isSessionAboutToEnd();
      if((this as any).session && (this as any).session.constructor === Session && (this as any).session.sessionType && !valid){
        this.isOpenLogout = true;
        await this.logoutUser();
        this.setTimeoutLogout(false);
        this.isOpen = false;
        window.location.href = '/';
      }else if((this as any).session && (this as any).session.constructor === Session && (this as any).session.sessionType && !about){
        (this as any).showModal();
      }
    },
    runInterval() {
      if(!(this as any).isSessionValid()) {
        clearInterval(timer);
        (this as any).judgeSession();
      }else{
        (this as any).judgeSession();
      }
    },
    showModal(){
      try{
        setTimeout(() => {
          try{
            if(!this.isOpen){
              this.isOpen = true;
              (this as any).initCountDown();
            }
          }catch (e){
            console.log(e);
            this.isOpen = false;
          }
        }, 200);
      }catch (e){
        console.log(e);
        this.isOpen = false;
      }
    },
    initCountDown(){
      const _this = (this as any);
      setTimeout(() => {
        try{
          (this as any).timeLeft = ((this as any).sessionNearEnd);
          if((this as any).countDown === false)
            (this as any).countDown = setInterval(function(){
              _this.timeLeft = _this.timeLeft <= 0 ? 0 : _this.timeLeft - 1000;
              const timecounter = document.getElementById('timeLeft');
              if(timecounter){
                timecounter.textContent = String(_this.timeLeft / 1000);
              }
            }, 1000);
        }catch (e){
          console.log(e);
        }
      }, 500);
    },
    async continueSession(){
      (this as any).setSessionTime(new Date().getTime());
      await (this as any).extendSession();
      clearInterval(this.countDown as NodeJS.Timeout)
      this.timeLeft = 0;
      this.countDown = false;
      this.isOpen = false;
    },
    async endSession(){
      await this.logoutUser();
      this.setTimeoutLogout(false);
      this.isOpen = false;
      window.location.href = '/';
    },
    hideModal(){
      this.isOpenLogout = false;
    },
  },
  watch:{
    session:{
      deep: true,
      immediate: true,
      handler: function (newVal){
        if(newVal && newVal.sessionType){
          let sessionStartTime = newVal.startTime;
          let duration = newVal.duration;
          //if there is duration in the session, update here
          if(duration && duration > (this as any).tokenDuration){
            (this as any).tokenDuration = duration;
          }
          //if startTime was updated, update here
          if(sessionStartTime && sessionStartTime > (this as any).sessionCreation){
            (this as any).sessionCreation = sessionStartTime;
          }
          //if startTime was updated, update here
          if((this as any).tokenDuration >= (this as any).sessionDuration){
            (this as any).sessionDuration = (this as any).tokenDuration;
          }else{
            (this as any).sessionDuration = (this as any).defaultSessionDuration;
          }
        }
      }
    },
  },
  mounted() {
    try{
      if (window) {
        if (!(window as any).__gCrWeb) {
          window['__gCrWeb'] = {};//added to prevent __gCrWeb.instantSearch.setIOSParameters type of errors
        }
        this.getAppVersion();
      }
      
    }catch (e){
      console.log(e);
    }
  }
});
