import { GetterTree, ActionTree, MutationTree } from 'vuex';
import Vue from 'vue';
import Event from '~/entities/Event';
import _groupBy from 'lodash/groupBy';

export const state = () => ({
    events: [] as Event[],
    currentEvent: {} as Event,
    previousEvents: [] as Event[],
    upcomingEvents: [] as Event[],
    eventId: undefined as number | undefined,
    eventName: undefined as string | undefined
});

export type RootState = ReturnType<typeof state>

export const mutations: MutationTree<RootState> = {
    setEvents: (state, value: any[]) => ( Vue.set(state, 'events', value) ),
    setEventId: (state, value: any[]) => ( Vue.set(state, 'eventId', value) ),
    setEventName: (state, value: any) => ( Vue.set(state, 'eventName', value) ),
    setCurrentEvent: (state, value: {}) => { Vue.set(state, 'currentEvent', value)},
    setPreviousEvents: (state, value: any[]) => { 
        Vue.set(state, 'previousEvents', [...state.previousEvents, value]);
        if(state.previousEvents.constructor === Array){
            state.previousEvents.sort(function(a:any,b:any){
              let firstDate:Date = new Date(b.startDate);
              let secondDate:Date = new Date(a.startDate);
              return  firstDate.getDate() - secondDate.getDate();
            });
        }
    },
    setAllUpcomingEvents: (state, value: any[]) => { Vue.set(state, 'upcomingEvents', value)},
    setUpcomingEvents: (state, value: any[]) => {
        Vue.set(state, 'upcomingEvents', [...state.upcomingEvents, value]);
        if(state.upcomingEvents.constructor === Array){
          state.upcomingEvents.sort(function(a:any,b:any){
            let firstDate:Date = new Date(b.startDate);
            let secondDate:Date = new Date(a.startDate);
            return  firstDate.getDate() - secondDate.getDate();
          });
        }
    }
}

export const getters: GetterTree<RootState, RootState> = {
    events : (state) => state.events,
    previousEvents : (state) => state.previousEvents,
    upcomingEvents : (state) => state.upcomingEvents,
    currentEvent: (state) => state.currentEvent,
    eventId: (state) => state.eventId,
    eventName: (state) => state.eventName
}

export const actions: ActionTree<RootState, RootState> = {
    checkStorage({ commit, dispatch }, value) {
        let storage: string | null = sessionStorage.getItem('events');
        if(storage?.length) {

            commit('setEvents', JSON.parse(storage));
        } else {
            dispatch('getEvents');
        }
        return;
    },
    eventRequest() {
        return Promise.all([this.$axios.$get(`/api/BicycleEvent`)]);
    },
    partnersRequest() {
        return Promise.all([this.$axios.$get(`/api/BicycleEvent/partners`)]);
    },
    eventCodeRequest({getters}, payload) {
        return Promise.all([this.$axios.$get(`/api/BicycleEvent/byurlcode/${payload}`)]);
    },
    async currentEventRequest({getters}) {
        let eventId:number | string = getters.eventId;
        return Promise.all([this.$axios.$get(`/api/BicycleEvent/` + eventId)]);
    },
    async getEventsTest({commit, dispatch}) {
        try {
            let events = await dispatch('eventRequest');
            commit('setEvents', events.value);
            dispatch('parseEvents');
        } catch (error) {
            console.log(error);
        }
        return
    },
    async getCurrentEvent({commit, dispatch}){
        try {
            let event = await dispatch('currentEventRequest');
            commit('setCurrentEvent', event[0].value);
            return event[0].value;
        } catch (error) {
            console.log(error);
        }
        return
    },
    async returnEvent({commit, dispatch}){
        try {
            let event = await dispatch('currentEventRequest');
            return event[0].value
            commit('setCurrentEvent', event[0].value);
        } catch (error) {
            console.log(error);
            return false
        }
        return
    },
    async getEvents({commit, dispatch}) {
        try {
            let events = await dispatch('eventRequest');
            commit('setEvents', events[0].value);
            //dispatch('parseEvents');
        } catch (error) {
            console.log(error);
        }
        return
    },
    async getPartners({commit, dispatch}) {
        try {
            let events = await dispatch('partnersRequest');
            commit('setEvents', events[0].value);
            //dispatch('parseEvents');
        } catch (error) {
            console.log(error);
        }
        return
    },
    async searchEvent({commit, getters ,dispatch}){
        await dispatch('getEvents');
        let selectedEvent:Event[] = getters.events.filter((event:Event) => parseEventName(event?.name?.toLowerCase()) === parseEventName(getters.eventName?.toLowerCase()) ||  event.urlCode?.toLowerCase() === getters.eventName.toLowerCase());
        if(selectedEvent.length > 0){
            commit('setEventId', selectedEvent[0].eventId);
            dispatch('getCurrentEvent');
        } else {
            return false
        }
    },
    async getEventId({getters ,dispatch}){
        try {
            await dispatch('getEvents');
            let selectedEvent:Event[] = getters.events.filter((event:Event) => parseEventName(event.name?.toLowerCase()) === parseEventName(getters.eventName?.toLowerCase()) || event.urlCode?.toLowerCase() === getters.eventName.toLowerCase());        
            if(selectedEvent.length > 0){
                return Promise.all([this.$axios.$get(`/api/BicycleEvent/` + selectedEvent[0].eventId)])
            } else {
                false;
            }
        } catch(err) {
            console.log(err)
        }
    },
    async getEventByUrl({commit, getters ,dispatch}){
        try {
            let response = await dispatch('eventCodeRequest', getters.eventName);
            commit('setCurrentEvent', response[0].value);
            return response
        } catch(err) {
            try {
                let response = await dispatch('eventCodeRequest', formatEventName(getters.eventName));
                commit('setCurrentEvent', response[0].value);
                return response
            } catch(err) {
                console.log(err)
            }
        }
    },
    async getPartnerId({getters ,dispatch}){
        try {
            await dispatch('getPartners');
            let selectedEvent:Event[] = getters.events.filter((event:Event) => parseEventName(event.name?.toLowerCase()) === parseEventName(getters.eventName?.toLowerCase()) || event.urlCode?.toLowerCase() === getters.eventName.toLowerCase());
            if(selectedEvent.length > 0){
                return Promise.all([this.$axios.$get(`/api/BicycleEvent/` + selectedEvent[0].eventId)])
            } else {
                false;
            }
        } catch(err) {
            console.log(err)
        }
    },
    async searchPartners({commit, getters ,dispatch}){
        await dispatch('getPartners');
        let selectedEvent:Event[] = getters.events.filter((event:Event) => parseEventName(event.name?.toLowerCase()) === parseEventName(getters.eventName?.toLowerCase()) || event.urlCode?.toLowerCase() === getters.eventName.toLowerCase());
        if(selectedEvent.length > 0){
            commit('setEventId', selectedEvent[0].eventId);
            dispatch('getCurrentEvent');
        } else {
            return false
        }
    },
    parseEvents({commit, getters}) {
        let events = getters.events;
        events.forEach((event:Event, index) => {
            let previousEvents:any[] = getters.getPreviousEvents;
            let upcomingEvents:any[] = getters.upcomingEvents;
            let startDate = new Date(event.startDate);
            let currentDate = new Date();
            let month = startDate.getMonth();
            if(month){
                if(startDate > currentDate ){
                    let eventInMonth: Event[] = upcomingEvents.length && upcomingEvents.constructor === Array  ? upcomingEvents : [];
                    commit('setUpcomingEvents', event);
                } else {
                    commit('setPreviousEvents', event)
                }
            }
        });
        let upcomingEvents:any[] = getters.upcomingEvents;
        let eventsParse = _groupBy(upcomingEvents, function(event) {
            return event.startDate.substring(0,4);
        })
        for (let i in eventsParse) {
            events[i] =_groupBy(events[i], function(event) {
                return event.startDate.substring(5,7);
            })
        }
        commit('setAllUpcomingEvents', eventsParse)
        /* upcomingEvents = eventsParse; */
    }
}

function parseEventName(name:string){
    if(name){
        return name.replaceAll("-", "_").replaceAll("_", " ");
    }
    return ''
}

function formatEventName(name:string){
    if(name){
        name = name.replace(/_/g, '-');
        name = name.replace(/---/g, 'tempReplace');
        name = name.replace(/-/g, ' ');
        name = name.replace(/tempReplace/g, ' - ');
        return name
    }
    return ''
}
