import  AVSAddressOptions  from "./AVSAddressOptions";

export enum DetailsSource {
    default = 'default',
    savedAddress = 'saved_address',
    recentSearches = 'recent_searches',
    event = 'event',
}

export enum AVSStatus {
    success = 'success',
    failed = 'failed'
}

export enum AVSSubStatus {
    notCalled= "not_called",
    rejectedByUser= "rejected_by_user",
    modifiedByUser= "modified_by_user",
    invalidAddress= "invalid_address", // Please enter a valid address number
    addressNotFound= "address_not_found",// Address not found
    streetNotFound= "street_not_found",
    validLicenseKey = 'invalid__license_key', // Please provide a valid license key for this web service
    outOfRange= "out_of_range",// Street number or box number out of range
    apiAVSFailed= "api_avs_failed",
    onAddressReplace="check_on_address_replace",
    unitNotFound="unit_not_found"
}

export class AvsStatusEnum {
    status?: AVSStatus;
    subStatus?: AVSSubStatus;
    source: DetailsSource = DetailsSource.default;

    public constructor (init?:Partial<AvsStatusEnum>) {
        Object.assign(this, init);
    } 
}

interface IAddressObject {
    address1: string;
    address2: string;
    address3: string;
    city: string;
    company: string;
    countryCode: string;
    isResidencial: boolean;
    priority: number;
    postalCode: string;
    state: string;
}

class AVSValue {
    addressOptions!: AVSAddressOptions[];
    passedAvs!: boolean;
    errorCode!: string | number;
    isChanged!: boolean;
    isResidential!: boolean;
    errorMessage?: string;
    originAddress!: IAddressObject;
    success!: boolean;
    validAddress!: IAddressObject;
    validationLevel!: number;
    validationSource!: number;

    public constructor(init?:Partial<AVSValue>) {
        Object.assign(this, init);
    }
}
export default class AVS {    
    public value!: AVSValue;
    public links!: any[];

    public constructor(init?:Partial<AVS>) {
        Object.assign(this, init);
    }
}

