export default function ({ route, store }) {
    if (process.client && typeof window.gtag === 'function' && isValidRoute(route.name)) {
      try {
        window.gtag('event', 'page_impression', {
          'pagePath': route.fullPath,
          'pageTitle': route.name,
          'visitorType': store.state.auth?.loggedIn ? 'customer' : 'guest' 
        });
      } catch (error) {
        console.error('Failed sending pageview to Google Analytics', error);
      }
    }
}


function isValidRoute(route){
    if(route === 'events-event' || route === 'event' || route === 'partners-event' || route.name === 'ship'){
      return false;
    }
    return true
}