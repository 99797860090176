
    import Vue from 'vue';
    import { mapActions, mapGetters } from 'vuex'

    export default Vue.extend({
        data() {
            return {
            }
        },
        computed: {
          // Vuex getters
          ...mapGetters({
            numServed: 'numServed',
          }),
        },
        methods:{
          // Vuex actions
          ...mapActions({
            getNumServed: 'getNumServed',
          }),
        },
        created(){
          this.getNumServed();
        },
        fetchOnServer: false
    })

