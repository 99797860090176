import { render, staticRenderFns } from "./UserMenu.vue?vue&type=template&id=2afb01f8&"
import script from "./UserMenu.vue?vue&type=script&lang=ts&"
export * from "./UserMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Modal: require('/opt/atlassian/pipelines/agent/build/components/Modal.vue').default})
