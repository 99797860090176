import { GetterTree, ActionTree, MutationTree } from 'vuex';
import Vue from 'vue';
interface IStoredValue {
  valueId:number,
  key: string,
  value: number,
  created: string,
  updated: string
}

export const state = () => ({
    loading: false as boolean,

    packageLocation: {} as any,
    isFreshChat: false as boolean,
    ambassadors: [],
    reviewCount: 0 as number,

    numServed: {
      valueId: 0 as number,
      key: "numserved" as string,
      value: 0 as number,
      created: "" as string,
      updated: "" as string
    } as IStoredValue,
    version: {
      valueId: 0 as number,
      key: "numserved" as string,
      value: 0 as number,
      created: "" as string,
      updated: "" as string
    } as IStoredValue,
    versionAPP: process.env.VERSION_APP
});

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  loading : (state) => state.loading,
  isFreshChat : (state) => state.isFreshChat,
  packageLocation : (state) => state.packageLocation,
  ambassadors: (state) => state.ambassadors,
  reviewCount: (state) => state.reviewCount,
  numServed : (state) => state.numServed,
}

export const mutations: MutationTree<RootState> = {
    setLoading: (state, value: boolean) => ( Vue.set(state, 'loading', value) ),
    setFreshChat: (state, value) => {// set the searches
    state.isFreshChat = value;
  },
    setPackageLocation: (state, value) => {
      state.packageLocation = value
    },
    setAmbassadors: (state, value) => {
      let sortedValue = value.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      state.ambassadors = sortedValue;
    },
    setReviewCount: (state,value) => {
      state.reviewCount = value;
    },
    setNumServed: (state, value) => {
      state.numServed = value;
    },
    setVersion: (state, value) => {
      state.version = value;
    }
}

export const actions: ActionTree<RootState, RootState> = {
    //claims
    async createClaim ({}, data) { // do not remove empty object in parameter, without it the action does not recognize the parameter to receive from controller
      try{
        let formData = new FormData();
        let keys = Object.keys(data);

        for(let i=0; i< keys.length; i++){
          let key = keys[i];
          if(key == 'BillOfSalePics' || key == 'EstimatePics' || key == 'OutsidePics' || key == 'PackingPics' || key == 'InsidePics') {
            for(let j = 0 ; j < data[key].length; j++) {
              formData.append(key, data[key][j]);
            }
          } else {
            formData.append(key, data[key]);
          }
        }
        const response = await this.$axios.$post(`/api/Claim/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryfvGYpb3Ry7VHBYdM',
              'Authorization': 'Bearer ' +localStorage.getItem('jwt')
            },
            maxContentLength: 100000000,
            maxBodyLength: 1000000000
        });
        return response
      }catch (error:any){
        return error.response;
      }
    },

    // tracking
    async getTrackPackageLocation({dispatch, commit},data){
        const response = await (this as any).$axios.$get(`/api/PackageLocation/`+ (data ? data : ''));
        if(response){
          commit('setPackageLocation', response);
        }
    },

    //ambassadors
    async getAmbassadors ({dispatch, commit}){
      try{
        let response = await this.$axios.$get(`/api/Ambassador`);
        commit('setAmbassadors', response)
      }catch(e:any){
        return false;
      }
    },

    //QBP
    async postQBPTopShop ({dispatch, commit}, formData){
      try{
        let response = await this.$axios.$post(`/api/QbpTopShop`, formData);
        return true;
      }catch(e:any){
        return false;
      }
    },
    async getReviewCount({commit}){
      try{
        let response = await this.$axios.$get(`/api/StoredValue/bykey/reviewcount`);
        if(response){
          let parseValue = (parseInt(response.value)/1000).toFixed(1);
          commit('setReviewCount', parseValue);
          return response;
        }else{
          return false;
        }
      }catch(e:any){
        return false;
      }
    },

    //general
    async getNumServed ({commit}) {
      try{
        if(!this.getters['numServed']?.value){
          let served = await this.$axios.$get(`/api/StoredValue/bykey/numserved`, {withCredentials: false});
          if(served){
            commit('setNumServed', served);
          }
        }
        return this.getters['numServed'];
      }catch (e){
        return this.getters['numServed'];
      }
    },

    // check version app 
    async getAppVersion ({ dispatch}) {
      try{
        if(typeof window !== 'undefined' && !(window as any).versionCheckLoaded){
          const response = await dispatch('getAppVersionRequest')
          if(response){
            const currentVersion = this.state.versionAPP;
            document.addEventListener("visibilitychange", async () => {
              if (document.visibilityState === "visible") {
                const response = await dispatch("getAppVersionRequest");
                checkVersionApp(currentVersion, response.value);
              }
            });
            checkVersionApp(currentVersion, response.value);
            (window as any).versionCheckLoaded = true;
          }
          return response;
        }
        return false;
      }catch (e){
        return false;
      }
    },
    async getAppVersionRequest () {
      try{
        return await this.$axios.$get(`/api/StoredValue/bykey/client-version`);
      }catch (e){
        return false;
      }
    },
}

function checkVersionApp(currentVersion, responseVersion){
  if(currentVersion !== responseVersion){
    window.location.reload();
  }
}
