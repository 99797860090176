
    import Vue from 'vue';
    import {mapActions, mapMutations} from "vuex";
    import notice from "~/entities/Notice";

    export default Vue.extend({
        data() {
          return {
            email: '',
            submitted: false,
            confirmed: false,
            error: false,
            successNotice: undefined
          }
        },
        methods: {
            ...mapMutations({
              setLoading: 'setLoading',
            }),
            ...mapActions({
              forgetPassword: 'user/forgetPassword',
            }),
            isValidEmail(){
              let validEmail = this.$refs.authForgot as HTMLInputElement
              return validEmail ? validEmail.checkValidity() : false;
            },
            async submitForgotPassword(){
              this.error = false;
              if(this.email && this.isValidEmail()){
                let success = await this.forgetPassword({email: this.email});
                if(success){
                  //emit confirmation
                  this.confirmed = true;
                  this.submitted = false;
                }else{
                  this.submitted = true;
                  this.error = true;
                }
              }else{
                this.submitted = true;
              }
            },
            onSwitchForm() {
                this.$emit('switchForms', 0);
            }
        },
        mounted(){
          (this as any).successNotice = new notice();
          (this as any).successNotice.type = 'alert-success';
          (this as any).successNotice.message = 'We\'ve sent you an email with a link to reset your password. Please check your email.';
          (this as any).successNotice.expletive = 'Success';
        },
    });
