import Vue from "vue";
import Search from "~/entities/Search";
import User from "~/entities/User";


export default class Session {
    private id!: string;
    public startTime!: number;
    public searches!: Array<Search>; //search stack, size of 3. every push pops the first element of the array, and reassign searches id
    public activeSearch!: Search ; // Used as a replacement for active search lookup through the searches array
    private searchLimit = 3;
    public sessionType!: number //used to indicate if the session is for logged user or guest. 0 for guest, 1 for logged user
    public user?: User
    public duration!: number;

    public constructor(init?:Partial<Session>, type = 0) {//this will be passed a json string or something from the localstorage. will have to initiate the search objects
        if(init && init instanceof Session){
          Object.assign(this, init);
        }else{
          let id = Session.uuidv4();
          let now = new Date().getTime();
          let searches = [];
          this.id = id;
          this.startTime = now;
          this.searches = searches;
          this.sessionType = type;
          this.duration = 0;
        }
    }

    public setID(id:string): void{
      this.id = id;
    }

    public getID(): string{
      return this.id;
    }

    public getSearch(index:number): Search{
      return this.searches[index];
    }

    public addSearch(search:Search): void{

      if(this.sessionType){
        search.savedSearchId = search.savedSearchId ? search.savedSearchId : undefined;
        search.savedSearchCode = search.savedSearchCode ? search.savedSearchCode : undefined;
        if(this.user && this.user.constructor === User && this.user.getID){
          search.ownerId = this.user.getID();
        }
      }

      // if its setting all saved searches do pop, its creating new one put in front of array

        this.searches.push(search);
        if(this.searches.length > this.searchLimit){
          this.searches.splice(0,1);
        }
      // @TODO: remove after changes
      this.reassignSearchesIndexes();

    }

    public setSearches(searches:[] | Array<Search>): void{//used only when restoring the session

        let formattedSearches = [] as any;
        for(let step = 0 ; step < searches.length ; step++){
          let search = new Search(step,true);
          let validSearch = search.setSearchData(searches[step], true);//check here: receives value with active: true.
          if(validSearch){
            formattedSearches.push(search);
          }
        }
        this.searches = formattedSearches;
        this.reassignSearchesIndexes();
    }

    public setGuest(guest:User){
      if(guest){
        this.startTime = new Date().getTime();
        this.user = guest;
        this.sessionType = 0;
      }
    }

    public setUser(user:User, refresh:boolean = true){
      if(user){
        if(refresh){
          this.startTime = new Date().getTime();
        }
        Vue.set(this, 'user', user)
        this.user = user;
        this.sessionType = 1;
        this.setDuration(this.user.exp);
      }
    }

    public setGuestUser(data:any){
      this.startTime = new Date().getTime();
      this.sessionType = 0;
      let user = new User();
      user.email = data.email;
      user.firstName = data.firstName;
      user.lastName = data.lastName;
      this.user = user;
    }

    public unsetUser(){
      if(this.user){
        this.startTime = new Date().getTime();
        this.user = undefined;
        this.sessionType = 0;
        this.unsetDuration();
      }
    }

    public unsetGuestUser(){
      if(this.user && this.user.email && this.sessionType === 0){ //added a sessionType check to prevent unsetting user from session when search is resumed //this was added on BIK-244, since it was causing errors with search resume - I know, wrong task but it should prevent lots of headaches... or cause more
        this.startTime = new Date().getTime();
        this.user.email = '';
        this.user.firstName = '';
        this.user.lastName = '';
        this.sessionType = 0;
        this.unsetDuration();
      }
    }

    public getUser(){
        return this.user;
    }

    public setDuration(expiracy){
      const expiracyDate = new Date(expiracy); // create a Date object from the string date
      const expirationTime = expiracyDate.getTime(); // get the expiration time in milliseconds
      const duration = expirationTime - this.startTime; // calculate the time difference
      this.duration = duration; // set the duration property of the object
    }

    public unsetDuration(){
      this.duration = 0;
    }

    public removeSearch(search:Search | number): void{
        if(search && search instanceof Search){
            let searchIndex = 0;
            searchIndex = this.searches.indexOf(search);//check this, probably wont work
            this.searches.splice(searchIndex, 1);
        }else{
            this.searches.splice(search, 1);
        }
        this.reassignSearchesIndexes();
    }

    public cleanSearches(): void{
        this.searches = [] as Array<Search>;
    }

    public makeActiveSearch(search:Search): void{
        this.activeSearch = search;
    }

    public getActiveSearch(): Search{
      //there SHOULD be just one active search and just one search with the flag set to true
      //but the logic is that the active search will be the most recent search with an active flag set to true.
      //if none is present, then it should add a search and make it active

      return this.activeSearch;
    }

    public reassignSearchesIndexes():void{
        if(this.searches.length){
            for(let step = 0 ; step < this.searches.length ; step++){
              /* 'typeof this.searches[step].setProperty === "function"' wasn't executing */
              Vue.set(this.searches[step], 'id', step);
            }
        }
    }

    public reassignActiveSearch():void{
      (this as any).activeSearch = undefined;
    }

    public setSearchProperty(index, property, value){
      if(this.searches.length > index){
        this.searches[index][property] = value;
      }
    }

    private static uuidv4(): string {
      return "id" + Math.random().toString(16).slice(2);
    }

}
